import { Field, Form, Formik } from "formik";
import { navigate } from "gatsby";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Input } from "../../components/InputFields";
import { FormLayout } from "../../components/Layout";
import { addMessage } from "../../state/reducers/appReducer";
import { loginAction } from "../../state/reducers/authReducer";
import { RootState } from "../../state/store";
import { fetchBaseOptions } from "../../utils/fetch";

export const RecoveryCodeSchema = yup.object().shape({
  recoveryCode: yup.string().length(36, "Invalid recovery code").required("Required"),
});

const RecoveryLoginPage = ({ location }: { location: { state: { next: string } } }) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <FormLayout seo={{ title: "Login with a recovery code" }}>
      <Formik
        initialValues={{ recoveryCode: "" }}
        validationSchema={RecoveryCodeSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          fetch("/api/2fa/recovery/login", {
            ...fetchBaseOptions,
            body: JSON.stringify({ recoveryCode: values.recoveryCode, token: token }),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              if (res.ok) {
                dispatch(loginAction());
                if (location.state.next) {
                  navigate(location.state.next);
                } else {
                  navigate("/app");
                }
              } else {
                dispatch(addMessage(j));
              }
              resetForm();
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-2">
            <Field
              required={true}
              type="text"
              name="recoveryCode"
              label="Recovery code"
              component={Input}
            />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
};

export default RecoveryLoginPage;
